<template>
  <span>
    <v-dialog
      v-model="isOpen"
      persistent
      max-width="300"
    >
    <v-card>
      <v-card-title>
        <div class="text-h6">
          Notice
        </div>
      </v-card-title>
      <v-card-text>
        <div>
          We currently require special scheduling with this course, We will contact you with details after purchase.
        </div>
      </v-card-text>
            <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="doClose"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="doContinue"
        >
          Continue
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </span>
</template>

<script>
export default {
  name: 'addToQueue',
  data: () => ({
    isOpen: false
  }),
  methods: {
    doOpen () {
      this.isOpen = true
    },
    doClose () {
      this.isOpen = false
    },
    doContinue () {
      this.$emit('doStoreItemToCart')
      this.doClose()
    }
  }
}
</script>

<style scoped>

</style>
